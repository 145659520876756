/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
.user-nav .dropdown:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -15px;
  width: 0;
  height: 30px;
  display: block;
  border-right: 1px solid #eeeeee; }

.header_user_info a {
  padding: 0 15px; }
